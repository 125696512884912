<template>
  <div class="row">
    <div class="col-md-4" style="padding-left: 4px; padding-right: 4px;">
      <card class="stacked-form">
        <form method="#" action="#" @submit.prevent>
          <div slot="header">
            <h4 class="card-title">Cliente</h4>
            <p class="card-category">Generación de Pedido</p>
          </div>
          <div>
            <fg-input label="Fecha"
                      inputClasses="col-md-4 col-4"
                      type="text"
                      readonly=""
                      v-model="fechaesp">
            </fg-input>
            <!--<span style="color: gray; font-size: 12px;">USUARIO</span><br>
            <el-select size="large" placeholder="Elige usuario" v-model="usuario_id" @change="cambiaUsuario">
              <el-option v-for="usuario in usuarios" :value="usuario.id" :label="usuario.usuario" :key="usuario.id">
              </el-option>
            </el-select>-->
            <fg-input label="Pedido ERP"
              inputClasses="col-md-3 col-3"
              type="text"
              readonly=""
              v-model="pederp">
            </fg-input>
            <fg-input label="Comentario">
              <textarea class="form-control" v-model="comentario" placeholder="Introduce tu comentario" rows="4"></textarea>
            </fg-input>
            <br>
            <l-button type="primary" v-if="(usuario_id!='' && tableData.length>0) && nuevo==true" style="margin-top: 20px;" @click.once="generaPedido()">Generar</l-button>
            <l-button type="primary" v-if="(usuario_id!='' && tableData.length>0) && nuevo==false" style="margin-top: 20px;" @click.once="modificaPedido()">Modificar</l-button>
            <l-button type="primary" v-if="(usuario_id!='' && tableData.length>0) && nuevo==false && nuevolimpia==false" style="margin-top: 20px;" @click.once="generaPedidoNuevo()">Nuevo Pedido</l-button>
            <l-button type="primary" v-if="(usuario_id!='' && tableData.length>0) && nuevo==false && nuevolimpia==true" style="margin-top: 20px;" @click.once="generaPedido()">Graba Nuevo</l-button>
          </div>
        </form>
      </card>
    </div>
    <div class="col-md-8" style="padding-left: 4px; padding-right: 4px;">
      <card class="stacked-form" title="Ficha de Producto" style="padding-right: 4px;">
        <template slot="header">
          <h4 class="card-title">
            Pedido de Usuario {{usuario.usuario}}
          </h4>
          <span style="color: gray; font-size: 12px;">AÑADE PRODUCTO</span><br>
          <el-select size="large" placeholder="Elige producto" v-model="producto_id" @change="cambiaProducto">
            <el-option v-for="producto in productos" :value="producto.id" :label="producto.nombre" :key="producto.id">
            </el-option>
          </el-select>

        </template>


        <div class="table-responsive">
          <el-table v-if="usuario_id!=''" :data="tableData" :row-class-name="tableRowClassName" size="small" :cell-style="finalCellStyle">
              <el-table-column label="Producto" property="nomprod" :min-width="50"></el-table-column>
              <el-table-column label="Cant" property="cant" :min-width="31">
                <template slot-scope="scope">
                  <input class="form-control input-sm" style="text-align: right; font-size: 10px !important; width: 40px; padding: .70rem .25rem !important; line-height: 1.5;"
                    v-model="scope.row.cant"
                  ></input>
               </template>
              </el-table-column>
              <el-table-column label="A" property="marca" :min-width="19">
                <template slot-scope="scope">
                  <l-switch class="d-none d-lg-block" v-model="scope.row.marca" on-text="SI" off-text="NO"></l-switch>
                  <checkbox class="d-lg-none" v-model="scope.row.marca"></checkbox>
                </template>
              </el-table-column>
              <!--<el-table-column label="Acciones">
                <template slot-scope="props">
                    <a class="btn-neutral btn-simple btn-lg2 btn-link" v-tooltip.top-center="'Suma'" @click="handleSuma(props.$index, props.row)">
                      <i class="fa fa-plus-square-o"></i>
                    </a>
                    <a class="btn-neutral btn-simple btn-lg2 btn-link" v-tooltip.top-center="'Resta'" @click="handleResta(props.$index, props.row)">
                      <i class="fa fa-minus-square-o"></i>
                    </a>
                </template>
              </el-table-column>-->
          </el-table>
        </div>





        <br>
      </card>
    </div> <!-- end card -->


  </div>
</template>
<script>

  import { Checkbox, Radio, Switch as LSwitch } from 'src/components/index'
  import {Table, TableColumn, Select, Option} from 'element-ui'
  import Swal from 'sweetalert2'

  import axios from "axios";
  import crypt from "crypto-js";
  import AES from "crypto-js/aes";

  export default {
    components: {
      Checkbox,
      Radio,
      LSwitch,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Select.name]: Select,
      [Option.name]: Option
    },
    data () {
      return {
        tableData: [],
        usuario: {},


        usuarios: {},
        familias: {},
        productos: {},
        usuario_id: '',
        usuario_cliente: '',
        usuario_serie: '',
        producto_id: '',

        fichacab: {},
        listaped: {},

        nuevo: true,
        nuevolimpia: false,

        fechahora: '',
        fecha: '',
        fechaesp: '',
        comentario: '',
        pederp: '',

        colores: [{value: '1', label: '', color: 'bc1'},
        {value: '2', label: '', color: 'bc2'},
        {value: '3', label: '', color: 'bc3'},
        {value: '4', label: '', color: 'bc4'},
        {value: '5', label: '', color: 'bc5'},
        {value: '6', label: '', color: 'bc6'},
        {value: '7', label: '', color: 'bc7'},
        {value: '8', label: '', color: 'bc8'},
        {value: '9', label: '', color: 'bc9'},
        {value: '10', label: '', color: 'bc10'},
        {value: '11', label: '', color: 'bc11'},
        {value: '12', label: '', color: 'bc12'},
        {value: '13', label: '', color: 'bc13'},
        {value: '14', label: '', color: 'bc14'},
        {value: '15', label: '', color: 'bc15'},
        {value: '16', label: '', color: 'bc16'},
        {value: '17', label: '', color: 'bc17'},
        {value: '18', label: '', color: 'bc18'},
        {value: '19', label: '', color: 'bc19'},
        {value: '20', label: '', color: 'bc20'},
        {value: '21', label: '', color: 'bc21'}]
      }
    },
    created() {
      this.cargafamilias()
      this.cargaproductos()
      this.cargausuarios()
      var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
      var localISOTime = (new Date(Date.now() - tzoffset)).toISOString().slice(0, -1);
      // => '2015-01-26T06:40:36.181'
      var solofecha = localISOTime.slice(0,10)
      this.fechahora = localISOTime
      this.fecha = solofecha
      this.fechaesp = localISOTime.slice(8,10)+'/'+localISOTime.slice(5,7)+'/'+localISOTime.slice(0,4)
    },
    mounted() {
      this.usuario = this.$store.state.usuario
      this.usuario_id = this.$store.state.usuario.id
      this.usuario_cliente = this.usuario.cliente
      this.usuario_serie = this.usuario.serie
      this.comentario = ""
      this.pederp = ""
      this.cargalista(this.usuario_id)
      this.cuentapedido()

    },
    methods: {
      finalCellStyle(tabla){
        console.log("column: ",tabla.column.property)
        if (tabla.column.property="nomprod") {
          return "font-size: 11px;"
        }
        if (tabla.column.property="cant") {
          return "font-size: 10px;"
        }
      },
      cuentapedido() {
        var mijson = {
          funcion: "cuenta",
          tabla: "pedidos",
          where: "DATE(fecha)='"+this.fecha+"' and usuario_id='"+this.usuario_id+"' limit 1"
        }
        const params = new URLSearchParams();
        params.append('cadena', AES.encrypt(JSON.stringify(mijson),this.$store.state.salvoconducto));
        axios.post('https://capravps.erpgestion.es:443', params)
        .then(respuesta => {
          var resp = JSON.parse(crypt.enc.Utf8.stringify(AES.decrypt(respuesta.data, this.$store.state.salvoconducto)))
          if (resp.cont==0) {
            this.nuevo = true
          } else {
            this.nuevo = false
            this.cargaPedido()
          }
        })
        .catch(error => {
          if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
          } else if (error.request) {
              console.log(error.request);
          } else {
              console.log('Error', error.message);
          }
          console.log(error.config);
        })
      },

      cargaPedido() {
        //LEEMOS LA CABECERA
        var mijson = {
          funcion: "lista",
          tabla: "pedidos",
          campos: "*",
          where: "DATE(fecha)='"+this.fecha+"' and usuario_id='"+this.usuario_id+"' order by id DESC limit 1"
        }
        const params = new URLSearchParams();
        params.append('cadena', AES.encrypt(JSON.stringify(mijson),this.$store.state.salvoconducto));
        axios.post('https://capravps.erpgestion.es:443', params)
        .then(respuesta => {
          var resp = JSON.parse(crypt.enc.Utf8.stringify(AES.decrypt(respuesta.data, this.$store.state.salvoconducto)))
          this.fichacab = resp[0]
          this.comentario = this.fichacab.comentario
          this.pederp = this.fichacab.pedido_erp

          //LEEMOS LAS LINEAS DEL PEDIDO
          var mijson = {
            funcion: "lista",
            tabla: "pedlin",
            campos: "*",
            where: "pedidos_id='"+this.fichacab.id+"'"
          }
          const params = new URLSearchParams();
          params.append('cadena', AES.encrypt(JSON.stringify(mijson),this.$store.state.salvoconducto));
          axios.post('https://capravps.erpgestion.es:443', params)
          .then(respuesta => {
            var resp = JSON.parse(crypt.enc.Utf8.stringify(AES.decrypt(respuesta.data, this.$store.state.salvoconducto)))
            this.listaped = resp
            this.alimentaLista()
          })
          .catch(error => {
            if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
            console.log(error.config);
          })


        })
        .catch(error => {
          if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
          } else if (error.request) {
              console.log(error.request);
          } else {
              console.log('Error', error.message);
          }
          console.log(error.config);
        })

      },

      alimentaLista() {
        var lista = this.tableData
        var listadisco = this.listaped
        var usuid = this.usuario_id
        listadisco.forEach( function(valor, indice) {
          var encontrado = false
          lista.forEach(function(v2,i2) {
            if(v2.producto_id==valor.producto_id) {
              encontrado = true
              lista[i2].cant = valor.cantidad.replace(".",",")
              lista[i2].marca = ((valor.marca == 1) ? true : false)
            }
          })
          if (!encontrado) {
            var temp = {
               id: "9999",
               usuario_id: usuid,
               producto_id: valor.producto_id,
               nomprod: valor.nombre,
               cant: valor.cantidad.replace(".",","),
               marca: ((valor.marca == 1) ? true : false),
               orden: "9999"
             }
             lista.push(temp)
          }
        })

      },

      cargafamilias() {
        var mijson = {
          funcion: "lista",
          tabla: "familia",
          campos: "*",
          where: ""
        }
        const params = new URLSearchParams();
        params.append('cadena', AES.encrypt(JSON.stringify(mijson),this.$store.state.salvoconducto));
        axios.post('https://capravps.erpgestion.es:443', params)
        .then(respuesta => {
          var resp = JSON.parse(crypt.enc.Utf8.stringify(AES.decrypt(respuesta.data, this.$store.state.salvoconducto)))
          this.familias = resp
        })
        .catch(error => {
          if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
          } else if (error.request) {
              console.log(error.request);
          } else {
              console.log('Error', error.message);
          }
          console.log(error.config);
        })
      },
      cargausuarios() {
        var mijson = {
          funcion: "lista",
          tabla: "usuarios",
          campos: "*",
          where: "perfil=1"
        }
        const params = new URLSearchParams();
        params.append('cadena', AES.encrypt(JSON.stringify(mijson),this.$store.state.salvoconducto));
        axios.post('https://capravps.erpgestion.es:443', params)
        .then(respuesta => {
          var resp = JSON.parse(crypt.enc.Utf8.stringify(AES.decrypt(respuesta.data, this.$store.state.salvoconducto)))
          this.usuarios = resp
        })
        .catch(error => {
          if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
          } else if (error.request) {
              console.log(error.request);
          } else {
              console.log('Error', error.message);
          }
          console.log(error.config);
        })
      },
      cargaproductos() {
        var mijson = {
          funcion: "lista",
          tabla: "producto",
          campos: "*",
          where: ""
        }
        const params = new URLSearchParams();
        params.append('cadena', AES.encrypt(JSON.stringify(mijson),this.$store.state.salvoconducto));
        axios.post('https://capravps.erpgestion.es:443', params)
        .then(respuesta => {
          var resp = JSON.parse(crypt.enc.Utf8.stringify(AES.decrypt(respuesta.data, this.$store.state.salvoconducto)))
          this.productos = resp
        })
        .catch(error => {
          if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
          } else if (error.request) {
              console.log(error.request);
          } else {
              console.log('Error', error.message);
          }
          console.log(error.config);
        })
      },
      cambiaUsuario(id) {
        var misusuarios = this.usuarios
        this.nuevolimpia = false
        var usuario = {}
        misusuarios.forEach( function(valor, indice) {
          if (valor.id==id) {
             usuario = valor
          }
        })
        this.usuario = usuario
        this.usuario_id = id
        this.comentario = ""
        this.pederp = ""
        this.cargalista(id)
        this.cuentapedido()
      },
      cambiaProducto(id) {
        var plantilla = this.tableData
        var productos = this.productos
        var encontrado = false
        var producto = {}
        var usuid = this.usuario_id
        plantilla.forEach( function(valor, indice) {
          if (valor.producto_id==id) {
             encontrado = true
          }
        })
        if (!encontrado) {
          productos.forEach( function(valor, indice) {
            if (valor.id==id) {
               var temp = {
                 id: "9999",
                 usuario_id: usuid,
                 producto_id: valor.id,
                 nomprod: valor.nombre,
                 cant: "",
                 orden: "9999",
                 marca: false
               }
               plantilla.push(temp)
            }
          })

        }

      },
      cargalista(id) {
        var mijson = {
          funcion: "listaLJ",
          tabla: "plantillas",
          campos: "a.id, a.usuario_id, a.producto_id, a.orden, a.cant, a.marca",
          where: "a.usuario_id = "+String(id),
          order: "a.orden",
          ljtabla: "producto",
          ljid: "producto_id",
          ljcampo: "nombre",
          ljalias: "nomprod"
        }
        const params = new URLSearchParams();
        params.append('cadena', AES.encrypt(JSON.stringify(mijson),this.$store.state.salvoconducto));
        axios.post('https://capravps.erpgestion.es:443', params)
        .then(respuesta => {
          var resp = JSON.parse(crypt.enc.Utf8.stringify(AES.decrypt(respuesta.data, this.$store.state.salvoconducto)))
          resp.forEach(function(valor,indice) {
            var micant = valor.cant.replace(".",",")
            var mimarca = ((valor.marca == 1) ? true : false)
            resp[indice].cant = ""
            resp[indice].marca = mimarca
          })
          this.tableData = resp
        })
        .catch(error => {
          if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
          } else if (error.request) {
              console.log(error.request);
          } else {
              console.log('Error', error.message);
          }
          console.log(error.config);
        })
      },

      tableRowClassName(row) {
        var micolor = 0
        var mifamid = 0
        if (this.usuario_id!=='') {
          var misfamilias = this.familias
          var misproductos = this.productos
          misproductos.forEach( function(valor, indice) {
            if (valor.id==row.row.producto_id) {
              mifamid = valor.familia_id
              misfamilias.forEach(function(valor2, indice2) {
                if (mifamid==valor2.id) {
                  micolor = valor2.color
                }
              })
            }
          })
        }
        var clase = ''
        if (micolor>0) {
          clase = this.colores[micolor-1].color
        }
        return clase
      },

      generaPedidoNuevo() {
        var self = this
        var mitabla = this.tableData
        Swal.fire({
          title: 'Estás seguro?',
          text: '¿Realmente quieres generar un nuevo pedido en la misma fecha? ',
          icon: 'question',
          showCancelButton: true,
          confirmButtonClass: 'btn btn-success btn-fill',
          cancelButtonClass: 'btn btn-danger btn-fill',
          confirmButtonText: '¡Si, genéralo!',
          cancelButtonText: 'Cancelar',
          buttonsStyling: false
        }).then(function (valor) {
          if (valor.isDismissed == true) {
            console.log("cancelaron")
          } else {
            mitabla.forEach(function(valor,indice) {
              mitabla[indice].cant = ""
            })
            self.nuevolimpia = true
          }
        })
      },

      generaPedido() {
        this.nuevolimpia = false
        var fechahora = this.fechahora
        var fechahoraISO = fechahora.replace('T', ' ')
        var ultimaid = 0
        var lineas = this.tableData
        var productos = this.productos
        // (NUEVO)
        var promesas = []
        var self = this

        var lineastot = 0
        lineas.forEach(function(valor,indice) {
          var mivalor = parseFloat(valor.cant.replace(",","."))
          if (!isNaN(mivalor)) {
            lineastot++
          }
        })
        //var lineascont = 0
        console.log("lineastot: ", lineastot)

        if (lineastot == 0) {
          Swal.fire({
            title: '¡Aviso!',
            text: 'El pedido no se graba, no tiene líneas.',
            timer: 3000,
            showConfirmButton: false
          })
        } else {
          //ALTA CABECERA DE PEDIDO
          var mijson = {
            funcion: "altaNEid",
            tabla: "pedidos",
            campos: "usuario_id, comentario, fecha, cliente, serie",
            valores: "'"+this.usuario_id+"', '"+this.comentario+"', '"+fechahoraISO+"', '"+this.usuario_cliente+"', '"+this.usuario_serie+"'",
            where: ""
          }
          const params = new URLSearchParams();
          params.append('cadena', AES.encrypt(JSON.stringify(mijson),this.$store.state.salvoconducto));
          axios.post('https://capravps.erpgestion.es:443', params)
          .then(respuesta => {
            var resp = JSON.parse(crypt.enc.Utf8.stringify(AES.decrypt(respuesta.data, this.$store.state.salvoconducto)))
            if (resp.resp=="OK") {
              ultimaid = resp.lastid
              var sc = this.$store.state.salvoconducto

              //RECORREMOS LAS LINEAS DEL PEDIDO DE PANTALLA
              lineas.forEach(function(valor,indice) {
                var referencia = ""
                var mivalor = parseFloat(valor.cant.replace(",","."))

                productos.forEach( function(valor2, indice2) {
                  if (valor2.id==valor.producto_id) {
                     referencia = valor2.referencia
                  }
                })

                //GENERAMOS LAS LINEAS DEL PEDIDO SI LA CANTIDAD ES MAYOR QUE 0
                if (!isNaN(mivalor)) {
                  var mijson = {
                    funcion: "alta",
                    tabla: "pedlin",
                    campos: "pedidos_id, referencia, cantidad, producto_id, nombre, marca, orden",
                    valores: "'"+ultimaid+"', '"+referencia+"', '"+mivalor+"', '"+valor.producto_id+"', '"+valor.nomprod+"', '"+((valor.marca == true) ? 1 : 0)+"', '"+indice+"'"
                  }
                  const params = new URLSearchParams();
                  params.append('cadena', AES.encrypt(JSON.stringify(mijson),sc));
                  // CREAMOS PROMESA (NUEVO) EN ARRAY
                  promesas.push(axios.post('https://capravps.erpgestion.es:443', params))
                }

              }) //FIN RECORRER LINEAS
              // (NUEVO)
              axios.all(promesas)
              .then(function(respuestas){
                //var resp = JSON.parse(crypt.enc.Utf8.stringify(AES.decrypt(respuesta.data, sc)))
                console.log('axios.all respuestas: ',respuestas)
                //GRABAMOS FINALIZADO EN WEB
                var mijson = {
                  funcion: "modifica",
                  tabla: "pedidos",
                  campos: "finalizado_en_web",
                  valores: "1",
                  id: String(ultimaid)
                }
                const params = new URLSearchParams();
                params.append('cadena', AES.encrypt(JSON.stringify(mijson),sc));
                axios.post('https://capravps.erpgestion.es:443', params)
                .then(respuesta => {
                  var resp = JSON.parse(crypt.enc.Utf8.stringify(AES.decrypt(respuesta.data, sc)))
                  console.log(resp)
                  if (resp.resp=="OK") {
                    self.tableData = []
                    var id = self.usuario_id
                    self.comentario = ""
                    self.pederp = ""
                    self.cargalista(id)
                    self.cuentapedido()
                    Swal.fire({
                      title: '¡Aviso!',
                      text: 'El pedido ha sido grabado correctamente',
                      timer: 3000,
                      showConfirmButton: false
                    })
                  }
                })
                .catch(error => {
                  if (error.response) {
                      console.log(error.response.data);
                      console.log(error.response.status);
                      console.log(error.response.headers);
                  } else if (error.request) {
                      console.log(error.request);
                  } else {
                      console.log('Error', error.message);
                  }
                  console.log(error.config);
                })
              })
              .catch(function(errores){
                console.log('Promise.all errores: ',errores)
                Swal.fire({
                  title: '¡Aviso!',
                  text: 'Se ha grabado mal alguna línea de pedido',
                  timer: 3000,
                  showConfirmButton: false
                })
              })  //(FIN NUEVO)


            }
          })
          .catch(error => {
            if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
            console.log(error.config);
          })

        }




      },

      modificaPedido() {
        var fechahora = this.fechahora
        var fechahoraISO = fechahora.replace('T', ' ')
        var ultimaid = 0
        var lineas = this.tableData
        var productos = this.productos
        var fichacab = this.fichacab
        var ultimaid = fichacab.id
        var sc = this.$store.state.salvoconducto
        // (NUEVO)
        var promesas = []

        var lineastot = 0
        lineas.forEach(function(valor,indice) {
          var mivalor = parseFloat(valor.cant.replace(",","."))
          if (!isNaN(mivalor)) {
            lineastot++
          }
        })
        //var lineascont = 0
        console.log("lineastot: ", lineastot)

        //MODIFICAMOS CABECERA
        var mijson = {
          funcion: "modifica",
          tabla: "pedidos",
          campos: "enviado_a_erp, finalizado_en_web",
          valores: "0,0",
          id: ultimaid
        }
        const params = new URLSearchParams();
        params.append('cadena', AES.encrypt(JSON.stringify(mijson),sc));
        axios.post('https://capravps.erpgestion.es:443', params)
        .then(respuesta => {
          var resp = JSON.parse(crypt.enc.Utf8.stringify(AES.decrypt(respuesta.data, sc)))
          if (resp.resp=="OK") {

            //BORRAMOS LINEAS
            var mijson = {
              funcion: "bajaWE",
              tabla: "pedlin",
              where: "pedidos_id="+ultimaid
            }
            const params = new URLSearchParams();
            params.append('cadena', AES.encrypt(JSON.stringify(mijson),this.$store.state.salvoconducto));
            axios.post('https://capravps.erpgestion.es:443', params)
            .then(respuesta => {
              var resp = JSON.parse(crypt.enc.Utf8.stringify(AES.decrypt(respuesta.data, this.$store.state.salvoconducto)))
              if (resp.resp=="OK") {

                //RECORREMOS LAS LINEAS DEL PEDIDO DE PANTALLA
                lineas.forEach(function(valor,indice) {
                  var referencia = ""
                  var mivalor = parseFloat(valor.cant.replace(",","."))

                  productos.forEach( function(valor2, indice2) {
                    if (valor2.id==valor.producto_id) {
                       referencia = valor2.referencia
                    }
                  })

                  //GENERAMOS LAS LINEAS DEL PEDIDO SI LA CANTIDAD ES MAYOR QUE 0
                  if (!isNaN(mivalor)) {
                    var mijson = {
                      funcion: "alta",
                      tabla: "pedlin",
                      campos: "pedidos_id, referencia, cantidad, producto_id, nombre, marca, orden",
                      valores: "'"+ultimaid+"', '"+referencia+"', '"+mivalor+"', '"+valor.producto_id+"', '"+valor.nomprod+"', '"+((valor.marca == true) ? 1 : 0)+"', '"+indice+"'"
                    }
                    const params = new URLSearchParams();
                    params.append('cadena', AES.encrypt(JSON.stringify(mijson),sc));
                    // CREAMOS PROMESA (NUEVO) EN ARRAY
                    promesas.push(axios.post('https://capravps.erpgestion.es:443', params))
                  }
                }) //FIN RECORRER LINEAS
                // (NUEVO)
                axios.all(promesas)
                .then(function(respuestas){
                  //var resp = JSON.parse(crypt.enc.Utf8.stringify(AES.decrypt(respuesta.data, sc)))
                  console.log('axios.all respuestas: ',respuestas)
                  //GRABAMOS FINALIZADO EN WEB
                  var mijson = {
                    funcion: "modifica",
                    tabla: "pedidos",
                    campos: "finalizado_en_web",
                    valores: "1",
                    id: String(ultimaid)
                  }
                  const params = new URLSearchParams();
                  params.append('cadena', AES.encrypt(JSON.stringify(mijson),sc));
                  axios.post('https://capravps.erpgestion.es:443', params)
                  .then(respuesta => {
                    var resp = JSON.parse(crypt.enc.Utf8.stringify(AES.decrypt(respuesta.data, sc)))
                    console.log(resp)
                    if (resp.resp=="OK") {
                      Swal.fire({
                        title: '¡Aviso!',
                        text: 'El pedido ha sido grabado correctamente',
                        timer: 3000,
                        showConfirmButton: false
                      })
                    }
                  })
                  .catch(error => {
                    if (error.response) {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log('Error', error.message);
                    }
                    console.log(error.config);
                  })
                })
                .catch(function(errores){
                  console.log('Promise.all errores: ',errores)
                  Swal.fire({
                    title: '¡Aviso!',
                    text: 'Se ha grabado mal alguna línea de pedido',
                    timer: 3000,
                    showConfirmButton: false
                  })
                })  //(FIN NUEVO)


              }
            })
            .catch(error => {
              if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
              } else if (error.request) {
                  console.log(error.request);
              } else {
                  console.log('Error', error.message);
              }
              console.log(error.config);
            })

          }
        })
        .catch(error => {
          if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
          } else if (error.request) {
              console.log(error.request);
          } else {
              console.log('Error', error.message);
          }
          console.log(error.config);
        })

      },


      handleSuma(indice,fila) {
        var cant = parseFloat(fila.cant.replace(",","."))
        cant = cant + 1.00
        this.tableData[indice].cant = cant.toFixed(2).replace(".",",")

      },
      handleResta(indice,fila) {
        var cant = parseFloat(fila.cant.replace(",","."))
        cant = cant - 1.00
        this.tableData[indice].cant = cant.toFixed(2).replace(".",",")

      },


    }

  }
</script>
<style>
  .el-table .bc1 {
     background: #F08080;
   }
   .el-table .bc2 {
     background: #FFA07A;
   }
   .el-table .bc3 {
     background: #FFB6C1;
   }
   .el-table .bc4 {
     background: #FFA500;
   }
   .el-table .bc5 {
     background: #FFFACD;
   }
   .el-table .bc6 {
     background: #FFE4B5;
   }
   .el-table .bc7 {
     background: #F0E68C;
   }
   .el-table .bc8 {
     background: #E6E6FA;
   }
   .el-table .bc9 {
     background: #DDA0DD;
   }
   .el-table .bc10 {
     background: #66CDAA;
   }
   .el-table .bc11 {
     background: #3CB371;
   }
   .el-table .bc12 {
     background: #AFEEEE;
   }
   .el-table .bc13 {
     background: #B0C4DE;
   }
   .el-table .bc14 {
     background: #6495ED;
   }
   .el-table .bc15 {
     background: #FFEBCD;
   }
   .el-table .bc16 {
     background: #D2B48C;
   }
   .el-table .bc17 {
     background: #F0F8FF;
   }
   .el-table .bc18 {
     background: #FAEBD7;
   }
   .el-table .bc19 {
     background: #FAF0E6;
   }
   .el-table .bc20 {
     background: #FFF0F5;
   }
   .el-table .bc21 {
     background: #FFE4E1;
   }

   .el-table__row .el-input .el-input__inner{
     border-style:none;
   }
   .hover-row .el-input .el-input__inner{
     border-style:solid;
   }

</style>
